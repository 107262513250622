<template>
  <div class="financial-report-container">
    <CardHeader
      periodRange
      title="Relatório do faturamento"
      periodType="month"
      periodFormat="MMM YYYY"
      @changeOption="changeOption"
      @changePeriod="changePeriod"
    />
    <div class="chart">
      <VueApexCharts
        type="bar"
        width="100%"
        height="400px"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>
<script>
import CardHeader from '@/layouts/InvoicingSus/Dashboard/CardHeader'
import VueApexCharts from 'vue-apexcharts'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import moment from 'moment'

export default {
  components: { CardHeader, VueApexCharts },
  props: {
    susHealthPlansOptions: Array
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      period: [moment().startOf('month').toDate(), moment().add(1, 'M').endOf('month').toDate()],
      clinicHealthPlan: null,
      series: [],
      options: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return val ? parseNumberToMoney(val) : ''
          },
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          type: 'string',
          position: 'bottom',
          categories: [],
        },
        yaxis: {
          labels: {
            formatter(val) {
              return parseNumberToMoney(val)
            }
          }
        },
      },
    }
  },
  async created() {
    await this.getReport()
  },
  methods: {
    changePeriod(period) {
      this.showDataLabels = this.moment(period[1]).diff(this.moment(period[0]), 'months') < 5
      this.options = {
        ...this.options,
        dataLabels: {
          ...this.options.dataLabels,
          enabled: this.showDataLabels,
        }
      }
      this.period = period
    },
    changeOption(option) {
      this.clinicHealthPlan = option.value
    },
    async getReport() {
      const data = {
        start: this.period[0],
        end: this.period[1],
      }
      const dataApi = await this.getBillingReport(this.clinic.id, data)
      this.setLabelsAndSeries({...dataApi})
    },
    async getBillingReport(clinicId, item) {
      const { data } = await this.api.susBillingReport(clinicId, {
        start: item.start,
        end: moment(item.end).endOf('month').format('YYYY-MM-DD HH:mm:ss.SSSZ'),
        type: 'AIH'
      })
      return data
    },
    setLabelsAndSeries({ labels, invoicedValues, paidValues, recoveredValues, glossedValues }) {
      labels = labels.map(label => {
        return this.moment(label).format('MMM YYYY')
      })
      this.options = { ...this.options, labels };
      this.series = [
        {
          name: 'Faturado',
          color: '#305BF2',
          type: 'bar',
          data: invoicedValues
        },
        /* {
          name: 'Recuperado',
          color: '#A4B1DF',
          type: 'bar',
          data: recoveredValues
        }, */
        {
          name: 'Pago pela operadora',
          color: '#069763FF',
          type: 'bar',
          data: paidValues
        },
        {
          name: 'Glosado',
          type: 'bar',
          color: '#FF9900',
          data: glossedValues
      }];
    }
  },
  watch: {
    async period() { await this.getReport() },
    async clinicHealthPlan() { await this.getReport() },
  }
}
</script>
<style lang="scss" scoped>
  .financial-report-container {
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 24px;
  }
</style>
