<template>
  <div class="header">
    <div class="title">{{ title }}</div>
    <div class="actions">
      <slot></slot>
      <multiselect
        v-if="options.length"
        track-by="label"
        label="label"
        v-model="option"
        :options="options"
        :showLabels="false"
        :searchable="true"
        :allow-empty="false"
        :placeholder="optionPlaceholder"
        class="options"
        @select="value => $emit('changeOption', value)"
      >
        <template slot="caret">
          <ChevronDown class="chevron" />
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.label }}
        </template>
        <template slot="noOptions"> Nenhuma opção </template>
        <template slot="noResult"> Nenhum resultado </template>
      </multiselect>

      <b-form-group>
        <date-picker
          v-if="showPeriod"
          :range="periodRange"
          :type="periodType"
          v-model="period"
          :format="periodFormat"
          :clearable="false"
          :lang="langDatePicker"
          placeholder="Selecione um intervalo"
          @input="value => handlePeriod(value)"
        >
          <template slot="icon-calendar">
            <ChevronDown class="chevron" />
          </template>
        </date-picker>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: { ChevronDown },
  props: {
    title: { type: String, required: true },
    options: { type: Array, default: () => [] },
    optionPlaceholder:{ type: String, default: 'Selecionar...' },
    periodType:{ type: String, default: 'day' },
    periodRange: { type: Boolean, default: false },
    periodFormat: { type: String, default: 'DD [de] MMM [de] YYYY' },
    showPeriod: { type: Boolean, default: true },
  },
  data() {
    return {
      period: this.getPeriod(),
      option: this.options[0] || null,
      langDatePicker: {
        formatLocale: {
	        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      },
    }
  },
  methods: {
    handlePeriod(value){
      this.$emit('changePeriod', value)
    },
    getPeriod() {
      if (this.periodRange) {
        switch(this.periodType) {
          case 'day':
            return [this.moment().startOf('month').toDate(), this.moment().endOf('month').toDate()]
          case 'month':
            return [this.moment().startOf('month').toDate(), this.moment().add(1, 'month').startOf('month').toDate()]
          case 'year':
            return [this.moment().startOf('year').toDate(), this.moment().add(1, 'year').startOf('year').toDate()]
          default:
            return [null, null]
        }
      }

      switch(this.periodType) {
          case 'day':
            return this.moment().toDate()
          case 'month':
            return this.moment().startOf('month').toDate()
          case 'year':
            return this.moment().startOf('year').toDate()
          default:
            return null
        }
    }
  },
}
</script>
<style lang="scss" scoped>
  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 500;
      font-size: min(1.2vw, 16px);
      color: var(--dark-blue);
      margin-bottom: 0;
    }
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .options {
        width: 300px;
      }
    }
  }
  .chevron {
    stroke: var(--dark-blue) !important;
    fill: none !important;
  }
</style>
